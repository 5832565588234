import React from "react"
// import { useStaticQuery, graphql } from "gatsby"


const CTA = ({type, title, subtitle, url}) => {

    switch (type) {
        case "cta":

            return (
            <section className="cta-section">
                <div>
                <div className="uk-text-center">
                    <div className="section-content">
                    <h2 className="section-title">Start your personalised customer experience</h2>
                    <div className="section-subtitle">Do dolore occaecat amet Lorem aliquip. Est ea minim fugiat do incididunt incididunt id sit dolore ad. Cupidatat eu sint adipisicing qui enim. Do pariatur aliqua reprehenderit fugiat ipsum id nulla.</div>
                    <div className="cta-container">
                        <a href={"/"+url} className="cta-primary-btn accent">Free sign-up</a>
                    </div>
                    </div>
                </div>
                </div>
            </section>
            )

        case "cta-how-it-works":

            return (
            <section className="cta-section">
                <div>
                <div className="uk-text-center">
                    <div className="section-content">
                    <h2 className="section-title">Start your personalised customer experience</h2>
                    <div className="section-subtitle">Enter your name and email to see fingersnap.it in action!</div>
                    <div className="cta-container" >
                        <div><input type='text' placeholder='Enter your name here' className='input-line full-width'></input></div>
                        <div><input type='text' placeholder='Enter your email here' className='input-line full-width'></input></div>
                        <div><a href={url} className="cta-primary-btn accent">I'm interested</a></div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
            )

        default:
            return <div></div>
    }
}

export default CTA