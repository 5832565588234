import React from "react"

// import {Link} from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"
// import "../components/layout.css"

import SiteImg1 from "../assets/img/component-site.png"
import SiteImg2 from "../assets/img/component-form.png"
import SiteImg3 from "../assets/img/component-reuse.png"
import ValueImg from "../assets/img/fin-value-banner.png"

import SiteImgIntegration from "../assets/img/component-integration.png"
import CTA from "../components/cta"
import PromoSection from "../components/promo-section"


const IndexPage = () => (

  <div>
    <Layout>
      <SEO title="Fingersnap.it™" />
      
      
      <PromoSection img={ValueImg} titlePrefix={"We are"} title={"DAMn-Driven"} hasTrademark={true} description={"It is our puprose to be DAMn-Driven (Data Agnostic Management - not another CRM). It is not our intent to become another CRM, our aim is to ease the connection and data processing through various systems."}/>
 
      <section className="img-text-section" >
        
        <div data-uk-grid>
          <div className="uk-width-1-2@m uk-text-center section-img-container">
            <img className="section-img " src={SiteImg1} alt="SiteImg1"></img>
          </div>
  
  
          <div className="uk-width-1-2@m">
            <div className="section-content">
              <h2 className="section-title">Create your branded templates</h2>
              <div className="section-subtitle">Choose one of our predefined templates, or import one. Organise your content and customise your template so your team can re-use it. Impress your customers with interactive sections.</div>
            </div>
          </div>
        </div>
      </section>

      <section className="img-text-section">

        <div data-uk-grid>
          <div className="uk-width-1-2@m uk-text-center section-img-container uk-flex-last@m">
            <img className="section-img " src={SiteImg2} alt="SiteImg2" width="350"></img>
          </div>
  
          <div className="uk-width-1-2@m ">
            <div className="section-content">
              <h2 className="section-title">Easily share with customers or integrate in</h2>
              <div className="section-subtitle">Once you have created your content, you can share it. Our flexible approach allows you to share it in emails, embedded in your systems or use our API in your integrations.</div>
            </div>
          </div>
        </div>
        
      </section>

      <section className="img-text-section">

        <div data-uk-grid>
          <div className="uk-width-1-2@m uk-text-center section-img-container">
            <img className="section-img " src={SiteImg3} alt="SiteImg3" width="350"></img>
          </div>
  
          <div className="uk-width-1-2@m ">
            <div className="section-content">
              <h2 className="section-title">Seamless control on your process</h2>
              <div className="section-subtitle">Control the process by populating your template, or ask your customer to finalise by using interactive sections, digital sign-off or include online payment.</div>
            </div>
          </div>
        </div>
        
      </section>

       <section className="integration-section">
        <div>
          <div className="uk-text-center">
            <div className="section-content">
              <h2 className="section-title">Integrates with your favorite tools</h2>
              <img src={SiteImgIntegration} alt="siteIntegration"></img>
              <div className="section-subtitle">Easily integrate Fingersnap with all your favourite apps. Fingersnap.it will become your “Swiss-army knife” for digitising and automating your existing customer experience. </div>
            </div>
          </div>
        </div>
      </section>  

      {/* <CTA type="cta-how-it-works" url="https://asdv.com"/> */}

    </Layout>


    
  </div>
)

export default IndexPage
