import React from "react"
// import { useStaticQuery, graphql } from "gatsby"


const PromoSection = ({img, titlePrefix, title, description, hasTrademark}) => {

    

    return (
    <section className="promo-section">
        <div>
        <div className="uk-text-center">
            <div className="section-content">
            <h2 className="section-title"><span className="title-prefix">{titlePrefix}</span> {title}{hasTrademark? <sup>TM</sup> : ""}</h2>
            <div className="section-subtitle">{description}</div>
            <div className="promo-container">
                {/* <a href={"/"+url} className="promo-primary-btn accent">Free sign-up</a> */}
                <img src={img} alt="fin-value"/>
            </div>
            </div>
        </div>
        </div>
    </section>
    )

       
    
}

export default PromoSection